import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './App.css';

function ControlPeso() {
  const [weight, setWeight] = useState('');
  const [weights, setWeights] = useState([]);
  const [editWeight, setEditWeight] = useState(null);  // Para editar una entrada

  // Manejar el cambio en el input del peso
  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  // Registrar el peso en el backend
  const handleWeightSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editWeight) {
        // Si estamos editando un peso existente
        await axios.put(`https://controlpeso.marciglesias.dev/weights/${editWeight.id}`, { weight }, {
          withCredentials: true
        });
        setEditWeight(null);  // Limpiar el modo de edición
      } else {
        // Si estamos agregando un nuevo peso
        await axios.post('https://controlpeso.marciglesias.dev/weights', { weight }, {
          withCredentials: true
        });
      }
      setWeight('');
      fetchWeights();  // Actualizar los pesos después de agregar uno nuevo
    } catch (error) {
      console.error('Error recording weight:', error);
    }
  };

  // Obtener los pesos registrados desde el backend
  const fetchWeights = async () => {
    try {
      const response = await axios.get('https://controlpeso.marciglesias.dev/weights', {
        withCredentials: true
      });
      setWeights(response.data);
    } catch (error) {
      console.error('Error fetching weights:', error);
    }
  };

  // Llamar a fetchWeights cuando el componente se monta
  useEffect(() => {
    fetchWeights();
  }, []);

  // Editar una entrada de peso
  const handleEditWeight = (id, currentWeight) => {
    setEditWeight({ id, currentWeight });
    setWeight(currentWeight);  // Rellenar el campo con el peso actual
  };

  // Eliminar una entrada de peso
  const handleDeleteWeight = async (id) => {
    try {
      await axios.delete(`https://controlpeso.marciglesias.dev/weights/${id}`, {
        withCredentials: true
      });
      fetchWeights();  // Actualizar la lista después de eliminar
    } catch (error) {
      console.error('Error deleting weight:', error);
    }
  };

  // Datos para la gráfica
  const chartData = {
    labels: weights.map(weight => new Date(weight.date).toLocaleDateString()),
    datasets: [
      {
        label: 'Peso',
        data: weights.map(weight => weight.weight),
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,  // Permite personalizar el tamaño
    responsive: true,            // Hace la gráfica responsive
  };

  return (
    <div>
      <h2>Control de Peso</h2>
      <form onSubmit={handleWeightSubmit}>
        <input
          type="number"
          value={weight}
          onChange={handleWeightChange}
          placeholder="Ingresa tu peso"
          required
        />
        <button type="submit">
          {editWeight ? 'Modificar Peso' : 'Registrar Peso'}
        </button>
      </form>

      <section>
        <div>
          <h3>Entradas</h3>
          <div className="entradas-peso">
            <table>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Peso</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {weights.map((entry) => (
                  <tr key={entry.id}>
                    <td>{new Date(entry.date).toLocaleDateString()}</td>
                    <td>{entry.weight}</td>
                    <td>
                      <button onClick={() => handleEditWeight(entry.id, entry.weight)}>Modificar</button>
                      <button onClick={() => handleDeleteWeight(entry.id)}>Eliminar</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <h3>Gráfica de Peso</h3>
          <div className="grafica-contenedor">
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ControlPeso;
